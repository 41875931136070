<template>
  <content-section :spacing="true">
    <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
      <div>
        <title-plus
          :title="
            $t('components.riderDetailsManagement.diveIntoTab.points.headline')
          "
          :hide-plus="true"
        />
      </div>

      <div class="flex md:justify-end">
        <date-range-picker
          @apply-date="onApplyFilterDateRange"
          @cancel-date="onApplyFilterDateRange"
        />
      </div>
    </div>
    <section
      class="flex flex-col  mt-5 mb-10 space-y-5 lg:items-center lg:space-y-0 lg:space-x-5 lg:flex-row"
    >
      <summary-card
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.points.summary.totalPoints'
          )
        "
        :value="indexMetaData.summary.total"
        variant="gray"
        size="large"
      />

      <summary-card
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.points.summary.positivePoints'
          )
        "
        :value="indexMetaData.summary.positive"
        variant="gray"
        size="large"
      />

      <summary-card
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.points.summary.negativePoints'
          )
        "
        :value="indexMetaData.summary.negetive"
        variant="gray"
        size="large"
      />

      <summary-card
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.points.summary.reducedToZero'
          )
        "
        :value="indexMetaData.summary.reduce_to_zero"
        variant="gray"
        size="large"
      />
    </section>
    <template>
      <FSTable
        :fst-id="fstId"
        :endpoint="getEndPoint"
        :headers="getTableHeaders"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem :text="item.id" :truncate="-5" />
                <FSTableRowItem :text="item.title" />
                <FSTableRowItem>
                  <router-link
                    v-if="item.trip"
                    :to="{ name: 'ViewTrip', params: { id: item.trip } }"
                    target="_blank"
                  >
                    <span class="text-blue-500">
                      {{ $truncate(item.trip, -5) }}
                    </span>
                  </router-link>
                  <div v-else :key="`trip-hyperlink-${item.id}`">
                    --
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <router-link
                    v-if="item.report"
                    :to="{ name: 'ReportDetails', params: { id: item.report } }"
                    :key="`report-hyperlink-${item.id}`"
                  >
                    <span class="text-blue-500">
                      {{ $truncate(item.report, -5) }}
                    </span>
                  </router-link>
                  <div v-else :key="`report-hyperlink-${item.id}`">
                    --
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="
                    getFormattedDateTime(item.date, 'D MMM, YY hh:mm:ss a') ||
                      '--'
                  "
                />
                <FSTableRowItem>
                  <div
                    v-if="item.reduce_to_zero"
                    :key="`point-${item.id}`"
                    :class="`text-oRed`"
                  >
                    Reduced to zero
                  </div>
                  <div
                    v-else
                    :key="`point-${item.id}`"
                    :class="{ 'text-oRed': Number(item.point) < 0 }"
                  >
                    {{ item.point }}
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem :text="item.title" />
                <FSTableRowItem>
                  <router-link
                    v-if="item.trip"
                    :to="{ name: 'ViewTrip', params: { id: item.trip } }"
                    target="_blank"
                  >
                    <span class="text-blue-500">
                      {{ $truncate(item.trip, -5) }}
                    </span>
                  </router-link>
                  <div v-else :key="`trip-hyperlink-${item.id}`">
                    --
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <router-link
                    v-if="item.report"
                    :to="{ name: 'ReportDetails', params: { id: item.report } }"
                    :key="`report-hyperlink-${item.id}`"
                  >
                    <span class="text-blue-500">
                      {{ $truncate(item.report, -5) }}
                    </span>
                  </router-link>
                  <div v-else :key="`report-hyperlink-${item.id}`">
                    --
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div
                    v-if="item.reduce_to_zero"
                    :key="`point-${item.id}`"
                    :class="`text-oRed`"
                  >
                    Reduced to zero
                  </div>
                  <div
                    v-else
                    :key="`point-${item.id}`"
                    :class="{ 'text-oRed': Number(item.point) < 0 }"
                  >
                    {{ item.point }}
                  </div>
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.points.table.columns.id'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      #{{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.points.table.columns.date'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.date,
                          'D MMM, YY hh:mm:ss a'
                        ) || '--'
                      }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </template>
  </content-section>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { getFormattedDateTime } from '@/utils/datetime'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

import { useEndpoints } from '@/composables'
export default {
  name: 'ViewRiderUserPoints',
  components: {
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
          negetive: 0,
          positive: 0,
          reduce_to_zero: 0,
        },
        count: {
          total: 0,
          page: 0,
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      fstId: 'RiderPoints',
      tableHeaders: [
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.id'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.pointType'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.tripId'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.reportId'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.date'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.totalPoints'
          ),
          width: '20%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.pointType'
          ),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.tripId'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.reportId'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.points.table.columns.totalPoints'
          ),
          width: '20%',
          sort: null,
        },
      ],
    }
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getEndPoint() {
      return useEndpoints.user.org.pointIndex(this.$route.params.id)
    },
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
